import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import logo from './assets/images/logo.svg';
import bell from './assets/images/bell.svg';
import socialIco from './assets/images/socialIco.svg';
import linkIco from './assets/images/line.png';
import dew from './assets/images/dew.png';
import prof1 from './assets/images/prof1.png';
import prof2 from './assets/images/prof2.png';
import prof3 from './assets/images/prof3.png';
import prof4 from './assets/images/prof4.png';
import prof5 from './assets/images/prof5.png';
import waqar1 from './assets/images/waqar1.png';
import waqar2 from './assets/images/waqar2.png';
import media1 from './assets/images/media1.png';
import media2 from './assets/images/media2.png';
import media3 from './assets/images/media3.png';
import media4 from './assets/images/media4.png';
import media5 from './assets/images/media5.png';
import media6 from './assets/images/media6.png';
import award from './assets/images/award.png';
import arrowRight from './assets/images/arrow-right.svg';

import './App.css';

function App() {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="App">
      <header>
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <a className="navbar-brand" href="#">
              <img src={logo} alt="waqar zaka" />
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Service
                  </a>
                  <ul className="dropdown-menu">
                    <li><a className="dropdown-item" href="#">Action</a></li>
                    <li><a className="dropdown-item" href="#">Another action</a></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Portfolio
                  </a>
                  <ul className="dropdown-menu">
                    <li><a className="dropdown-item" href="#">Action</a></li>
                    <li><a className="dropdown-item" href="#">Another action</a></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a href='#' className="nav-link">About</a>
                </li>
                <li className="nav-item">
                  <a href='#' className="nav-link">Contact</a>
                </li>
              </ul>
              <div className="btnM">
                <button className="getStarted" onClick={handleShow}>Get started</button>
                <button className="bell"><img src={bell} alt="" /></button>
              </div>
            </div>
          </div>
        </nav>
          <div className="headerMain">
            <div className="container">
                <h1>Waqar zaka</h1>
            </div>
            <marquee behavior="smooth" direction="left" className="social1Main" onmouseover="this.scrollAmount = 0;">
              <div className="social1">
                <a href='https://www.facebook.com/waqarzaka' target='_blank' className="link">Facebook</a>
                <img className='socialIco' src={socialIco} alt="" />
                <a href='https://www.instagram.com/waqarzaka/' target='_blank' className="link">Instagram</a>
                <img className='socialIco' src={socialIco} alt="" />
                <a href='https://www.youtube.com/waqarzaka' target='_blank' className="link">Youtube</a>
                <img className='socialIco' src={socialIco} alt="" />
                <a href='https://twitter.com/ZakaWaqar' target='_blank' className="link">Twitter</a>
                <img className='socialIco' src={socialIco} alt="" />
                <a href='https://ae.linkedin.com/in/waqarzaka' target='_blank' className="link">Linkedin</a>
              </div>
            </marquee>
            <marquee behavior="" direction="right" className="social2Main" >
              <div className="social2">
                <a href='https://www.facebook.com/waqarzaka' target='_blank' className="link">Facebook</a>
                <img className='socialIco' src={socialIco} alt="" />
                <a href='https://www.instagram.com/waqarzaka/' target='_blank' className="link">Instagram</a>
                <img className='socialIco' src={socialIco} alt="" />
                <a href='https://www.youtube.com/waqarzaka' target='_blank' className="link">Youtube</a>
                <img className='socialIco' src={socialIco} alt="" />
                <a href='https://twitter.com/ZakaWaqar' target='_blank' className="link">Twitter</a>
                <img className='socialIco' src={socialIco} alt="" />
                <a href='https://ae.linkedin.com/in/waqarzaka' target='_blank' className="link">Linkedin</a>
              </div>
            </marquee>

            <div className="logoDiv">
              <img src={linkIco} alt="" />
              <img src={dew} alt="" />
            </div>
          </div>
      </header>

      <section className='profSumarySec'>
        <div className="container">
          <div className="row ai-center mb-4">
            <div className="col-md-6">
              <h1>Professional Summary</h1>
            </div>
            <div className="col-md-6">
              <p className='desc mb-0'>Waqar Zaka is one of the most famous Pakistani celebrities, social media influencers, and innovative content creators renowned for his significant impact on satellite TV through popular reality shows such as "Living on the Edge," "Over the Edge," and "Champions." These shows, celebrated on Pakistan’s major entertainment channels, have not only set trends but have also garnered international acclaim, influencing similar television formats across South Asia.</p>
            </div>
          </div>
          <div className="row pt-4 jc-center">
            <div className="col-md-4 mb-3">
              <a href='https://www.facebook.com/waqarzaka' target='_blank' className="sumaryCard">
                <img src={prof1} alt="summary" className="img-fluid" />
              </a>
            </div>
            <div className="col-md-4 mb-3">
              <a href='https://www.youtube.com/waqarzaka' target='_blank' className="sumaryCard">
                <img src={prof2} alt="summary" className="img-fluid" />
              </a>
            </div>
            <div className="col-md-4 mb-3">
              <a href='https://www.instagram.com/waqarzaka' target='_blank' className="sumaryCard">
                <img src={prof3} alt="summary" className="img-fluid" />
              </a>
            </div>
            <div className="col-md-4 mb-3">
              <a href='https://twitter.com/ZakaWaqar' target='_blank' className="sumaryCard">
                <img src={prof4} alt="summary" className="img-fluid" />
              </a>
            </div>
            <div className="col-md-4 mb-3">
              <a href='https://ae.linkedin.com/in/waqarzaka' target='_blank' className="sumaryCard">
                <img src={prof5} alt="summary" className="img-fluid" />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className='careerHighlights'>
        <div className="container">
          <h1 className='homeHeading'>Career Highlights</h1>
          <div className="row ai-center">
            <div className="col-md-6">
              <h2 className='executive'><span>Executive Producer </span> <br /> & TV Host</h2>
              <p>Successfully pioneered and hosted landmark reality TV shows, recognized for their innovative approach and mass appeal. "Living on the Edge," for instance, became a cultural phenomenon and was discussed in leading Indian newspapers for its wide influence.</p>
            </div>
            <div className="col-md-6">
              <div className="imgMain img1">
                <img src={waqar1} alt="" className='img-fluid' />
                <a href="https://www.hindustantimes.com/tv/roadies-plan-to-be-nice-on-season-9/story-87wCzDK9HR6DOP7MdtXeqJ.html" target="_blank" className="learnMore">Learn More</a>
              </div>
            </div>
          </div>
          <div className="row mt-4 ai-center">
            <div className="col-md-6">
              <div className="imgMain">
                <img src={waqar2} alt="" className='img-fluid' />
                <a href="https://www.brecorder.com/news/40092705" target="_blank" className="learnMore">Learn More</a>
              </div>
            </div>
            <div className="col-md-6">
              <h2 className='executive'>Top Crypto <br /> <span>Influencer</span></h2>
              <p>Waqar is now more famous for starting the trend of Bitcoin, crypto, and blockchain educational social media content since 2015. Acknowledged as a leading crypto expert, he has advocated for blockchain technology and cryptocurrencies in Pakistan, contributing to significant discourse and policy shaping.</p>
            </div>
          </div>
        </div>
      </section>

      <section className='mediaSec'>
        <div className="container">
          <h1 className='homeHeading'>Media and Public Engagements</h1>
          <div className="row">
            <div className="col-lg-6">
              <div className="galery">
                <img src={media1} alt="" />
                <img src={media2} alt="" />
                <img src={media3} alt="" />
                <img src={media4} alt="" />
                <img src={media5} alt="" />
                <img src={media6} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="content">
                <h2>Humanitarian and <br /> <span>Social Initiatives</span></h2>
                <div className="desc"><span></span>
                <p>
                Actively engaged in humanitarian missions, using his extensive social media reach to aid Muslim refugees in Myanmar and Syria, thus providing relief and raising global awareness about their situations. <a href="https://tribune.com.pk/story/1281898/watch-waqar-zaka-ring-new-year-people-syria" target="_blank" rel="noopener noreferrer">The Express Tribune</a>
                </p>
                </div>
                <div className="desc"><span></span>
                <p>
                  Awarded for his use of social media for humanitarian purposes by the Pakistani government. <a href="https://www.parhlo.com/waqar-zaka-wins-an-award" target="_blank" rel="noopener noreferrer">Parhlo</a>
                </p>
                </div>
                <a href="https://tribune.com.pk/story/1281898/watch-waqar-zaka-ring-new-year-people-syria" target="_blank" className="learnMore">Learn More</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="awardSec">
          <div className="row ai-center">
            <div className="col-md-8">
              <h2>Awards</h2>
              <div className="desc">
              Recognized with the Social Media Icon Award by a UAE-based company. Waqar used his acceptance speech to advocate for the expansion of award categories at the Pakistan International Screen Awards, enhancing recognition for emerging talents.
              </div>
              <div className="d-flex">
                <div className="awardM me-3">
                  <div className="name">[Bol News]</div>
                  <a href='https://www.bolnews.com/trending/2020/02/waqar-zaka-speaks-for-not-taking-pisa-award' target='_blank' className="link">Learn More</a>
                </div>
                <div className="awardM ">
                  <div className="name">[Something Haute]</div>
                  <a href='https://somethinghaute.com/the-7-member-pisa21-jury-reveal' target='_blank' className="link">Learn More</a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <img className='img-fluid' src={award} alt="" />
            </div>
          </div>
        </div>
      </div>

      <section className='eduSec'>
        <div className="container">
          <h1 className='homeHeading'>Educational Background</h1>
          <div className="degRow">
            <div className="col1">
              <div className="number">01</div>
              <div>
                <div className='name'>Bachelor of Engineering <br /> (Civil Engineering)</div>
                <div className="inst">NED University of Engineering and Technology, Karachi</div>
              </div>
            </div>
            <div className="col1">
              <div className="number">02</div>
              <div>
                <div className='name'>Business Program</div>
                <div className="inst">Stanford University, California, USA</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <section className='readyTo'>
          <h1>Ready to work with us ?</h1>
          <button className="getStarted" onClick={handleShow}>Get Started <img src={arrowRight} alt="" /></button>
        </section>
      </div>
      
      <footer>
        <div className="container">
          <div className="footerIn">
            <p className='mb-0'>© 2024 Waqarzaka - All Rights Reserved</p>
            <a href="" className='ms-auto'>Terms of use</a>
            <a href="" className='privacy'>Privacy policy</a>
          </div>
        </div>
      </footer>
        <Modal className='formModal' show={show} onHide={handleClose} centered >
         
          <Modal.Body>
            <div className="">
              <div className="form-group">
                <label className='mb-1'>Email</label>
                <input placeholder='Enter email' type="text" className='form-control' />
              </div>

              <button className="submitBtn" onClick={handleClose}>Submit</button>
            </div>
          </Modal.Body>
       
        </Modal>
    </div>
  );
}

export default App;
